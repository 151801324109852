<template>
  <div class="animated fadeIn container">
    <h1>문자야 발송 상세</h1>
    <table class="border-table mt-4">
      <tr v-if="smsRequest">
        <td style="width:280px">
          <p>{{ smsRequest.createdAt | dateFormat }}</p>
          <p v-show="summaryInfo.reservationDate != null" style="color:#edc028;margin-top:5px;">
            {{ summaryInfo.reservationDate | dateFormat }}
            <button
              v-show="showReservationCancel"
              class="btn btn-primary ml-2"
              @click="clickCancelReservation"
              :disabled="isCancelLoading"
              style="padding:3px 10px"
            >
              예약취소
            </button>
          </p>
        </td>
        <td>
          {{ smsRequest.message }}
        </td>
        <td style="width:250px">
          발송
          <span class="color-skyblue link" @click="requestAttachment('total', totalCount)">{{
            summaryInfo.totalCount
          }}</span>
          / 성공
          <span class="color-skyblue link" @click="requestAttachment('succeed', successCount)">{{
            summaryInfo.deliveredCount
          }}</span>
          / 대기
          <span class="color-skyblue link" @click="requestAttachment('wait', waitCount)">{{
            summaryInfo.waitCount
          }}</span>
          / 실패
          <span class="color-skyblue link" @click="requestAttachment('failed', failedCount)">{{
            summaryInfo.deliveryFailedCount
          }}</span>
        </td>
      </tr>
    </table>

    <ul class="recipient-list mt-4">
      <li :key="item.id" v-for="item in items">
        <span>{{ item.recipient | phoneFormat }}</span>
        <span class="status-label" v-bind:class="item.class" :title="item.title">{{ item.status }}</span>
      </li>
    </ul>
    <grid-loader :loading="isLoading" :color="loaderStyle.color" :size="loaderStyle.size" style="margin:20px auto">
    </grid-loader>
    <b-pagination :total-rows="smsRequest.totalCount" v-model="currentPage" :per-page="50" :limit="10" class="my-3">
    </b-pagination>
  </div>
</template>
<script>
import SmsService from '@/services/SmsService'
import moment from 'moment'
import GridLoader from 'vue-spinner/src/GridLoader.vue'

export default {
  name: 'SmsRequest',
  data: () => {
    return {
      isLoading: true,
      isCancelLoading: false,
      items: [],
      limit: 50,
      currentPage: 1,
      smsRequest: {},
      message: null,
      updatedAt: null,
      summaryInfo: {
        totalCount: 0,
        deliveredCount: 0,
        deliveryFailedCount: 0,
      },
      loaderStyle: {
        color: '#ccc',
        size: '8px',
      },
      showReservationCancel: false,
    }
  },
  components: {
    GridLoader,
  },
  mounted() {
    document.getElementsByClassName('app-body')[0].classList.add('bg-white')

    this.getRequest()
  },
  methods: {
    clickCancelReservation() {
      if (!confirm('예약문자를 취소하시겠습니까?')) {
        return
      }

      var t = this
      t.isCancelLoading = true
      SmsService.cancelReservation(this.$route.params.id)
        .then(() => {
          alert('예약이 취소되었습니다')
          t.$router.push({
            path: '/SmsRequests',
          })
        })
        .catch(function() {
          t.isCancelLoading = false
        })
    },
    requestAttachment(type, count) {
      if (count == 0) {
        return
      }

      if (this.totalCount >= 1000) {
        if (!confirm('대기시간이 걸릴 수 있습니다. 진행하시겠습니까?')) {
          return
        }
      }

      this.isLoading = true
      const t = this
      SmsService.getAttachment(this.$route.params.id, {
        type: type,
      })
        .then(response => {
          t.isLoading = false
          const url = window.URL.createObjectURL(new Blob(['\ufeff', response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', this.$route.params.id + '_' + type + '.csv')
          document.body.appendChild(link)
          link.click()
        })
        .catch(() => {
          t.isLoading = false
        })
    },
    isRecent(createdAt) {
      if (!createdAt || createdAt.length == 0) {
        return
      }

      var date = moment().add(-30, 'minutes')
      return date.isBefore(moment(String(createdAt)))
    },
    async getRequest() {
      const response = await SmsService.getRequest(this.$route.params.id)

      if (response.status === 200 && response.data) {
        this.smsRequest = response.data.smsRequest
        this.summaryInfo = response.data.summaryInfo
        if (this.smsRequest.unit) {
          this.getRequestUnit()
        }

        if (this.summaryInfo.reservationDate != null) {
          this.showReservationCancel = moment().isBefore(moment(this.summaryInfo.reservationDate))
        }
      }
    },
    paidMoney: function(count) {
      return parseFloat(count) * 19.8
    },
    async getRequestUnit() {
      var unit = this.smsRequest.unit[this.currentPage - 1]
      this.isLoading = true
      const response = await SmsService.getRequestUnit(this.smsRequest.id, unit.id)
      this.isLoading = false

      var list = response.data.list || []
      var classDic = {
        성공: 'success',
        실패: 'danger',
        대기: 'warning',
      }
      var items = []
      for (var i = 0; i < list.length; i++) {
        var item = list[i]
        items.push({
          recipient: item.recipient,
          status: item.status,
          class: classDic[item.status],
          title: item.status,
        })
      }

      this.items = items
    },
  },
  watch: {
    currentPage: function() {
      this.getRequestUnit()
    },
    isCancelLoading() {},
  },
}
</script>
<style scoped>
p {
  margin: 0;
}

.link {
  cursor: pointer;
}

.border-table tr {
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}

.recipient-list {
  width: 100%;
  display: block;
  overflow: hidden;
}

.recipient-list > li {
  position: relative;
  float: left;
  margin: 5px 0.5%;
  width: 19%;
  height: 53px;
  border: 1px solid #cccccc;
  padding: 15px 12px;
  border-radius: 3px;
}

.recipient-list > li > .status-label {
  position: absolute;
  top: 15px;
  right: 12px;
  padding: 3px 10px;
  font-size: 10px;
  border-radius: 2px;
  color: white;
}

.recipient-list > li > .success {
  background-color: #6cba7b;
}

.recipient-list > li > .danger {
  background-color: #e77470;
}

.recipient-list > li > .warning {
  background-color: #f6c244;
}
</style>
